@import '../../../css/Mixins.scss';

.notifications-containner-other {
    position: absolute;

    @include for-sm {
        display: block;
        width: 100%;
        display: flex;
        justify-content: end;
    }
}

.notifications-container {
    //margin-top: 64px;
    //margin: 0px 24px;
    position: relative;

    @include for-xs {
        //margin-top: 32px;
    }

    @include for-sm {
        //margin-top: 32px;
    }
}

.notification-negative-bottom-margin {
    margin-bottom: -60px;
}

.notification-modal-background {
    background-color: #333333;
    opacity: 0.92;
    position: fixed;
    display: flex;
    top: 0px;
    z-index: 99999;
    width: 100%;
    height: 100%;
    left: 0;
}

.notifications-containner-more {
    cursor: pointer;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    margin-top: 32px;
    margin-left: 28px;
    padding-top: 22px;
    color: #1F3DEB;
    display: flex;
    align-items: center;
    margin: 22px 0 20px;
    gap: 13px;

    @include for-xs {
        justify-content: center;
        margin: 22px 0 0px;
        gap: 12px;
        font-size: 16px;
    }

    @include for-sm {
        justify-content: center;
        margin: 16px 0 20px;
        padding-top: 0px;
        gap: 12px;
        font-size: 16px;
    }

    @include for-md {
        margin: 32px 0px 0px 28px;
        gap: 13px;
        margin-top: 16px;
        justify-content: end;
    }

    @include for-xxl {
        font-size: 18px;
    }
}

.notifications-containner-delete {
    display: flex;
    padding: 6px;
    align-items: flex-start;
    gap: 8px;
    position: absolute;
    right: -12px;
    top: -12px;
    border-radius: 80px;
    z-index: 999;
    background: var(--primary-primary-50, #F5F5FA);
    box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.04), 0px 3px 2px -1px rgba(0, 0, 0, 0.02);

    @include for-md {
        right: -10px;
        top: -10px;
    }
}

.notifications-containner-delete svg {
    width: 16px;
    height: 16px;
}

.notifications-containner-bell {
    position: fixed; 
    right: 20px;
    cursor: pointer;
    background: transparent;
}

.notifications-containner-bell-bell {
    position: relative; 
    border-radius: 50%;
    gap: 8px;
    background: rgb(190, 190, 190);
    box-shadow: 2px 2px 3px #999;
    padding: 10px;
}

.notifications-containner-bell svg {
    width: 32px;
    height: 36px;
}

.notifications-containner-bell svg path {
    stroke: white;
}


.notifications-containner-bell-red {
    position: absolute;
    height: 12px;
    width: 12px;
    background: red;
    border-radius: 50%;
    top: 0px;
    right: 0px;
}