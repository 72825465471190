@import './css/Mixins.scss';

body,
html {
    font-family: "Montserrat", sans-serif !important;
    overscroll-behavior: none;
    font-weight: 400;
    font-size: 18px;
    scroll-behavior: smooth;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    text-transform: none;
    width: 100%;
    overflow-x: hidden !important;
    overscroll-behavior-y: none;
}

body,
html {
    min-height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

input[type="date"]::-webkit-clear-button {
    -webkit-appearance: none;
    display: none;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}


input {
    border-radius: 0;
    -webkit-appearance: none;
  }

.page-container-main {
    display: flex;
    justify-content: center;
}

.page-container {
    margin-top: 56px;
    width: 480px;
    padding: 64px 32px;


    @include for-md {
        padding: 75px 0px;
        width: 480px;
    }

    @include for-lg {
        width: 480px;
        padding: 91px 0px;
    }

    @include for-xl {
        width: 544px;
        margin-top: 72px;
        padding: 75px 0px;
    }

    @include for-xxl {
        width: 600px;
        margin-top: 96px;
        padding: 83px 0px;
    }
}

.firebase-emulator-warning {
    display: none !important;
}

.nav-link-uthere {
    color: inherit;
    text-decoration: none;
}


html  {
    height: 100%;
    overflow: hidden;
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}
