@import '../../../../css/Mixins.scss';

.chat-button-animation-hover {
  &:hover {
    opacity: 1;
    color: white;
    background-size: 300% 300%;
    background-image: linear-gradient(-45deg,
        rgba(31, 61, 235, 1) 0%,
        rgba(31, 61, 235, 1) 25%,
        rgb(66, 94, 252) 51%,
        rgba(31, 61, 235, 1) 100%);
    animation: animate-bg-btn 4s ease infinite !important;
  }
}

.button-basic-desktop {
  display: flex;
  position: relative;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #1F3DEB;
  border-radius: 16px;
  border: none;
  pointer-events: all;
  transition: 0.3s;
  padding: 18px 22px;
  
  @include for-xs {
    padding: 16px 22px;
    font-size: 15px;
  }

  @include for-sm {
    padding: 16px 22px;
    font-size: 15px;
  }

  @include for-md {
    padding: 18px 32px;
    font-size: 16px;
  }

  @include for-lg {
    font-size: 16px;
    min-width: 160px;
  }

  @include for-xl {
    padding: 22px 40px;
    font-size: 17px;
  }

  @include for-xxl {
    padding: 22px 36px;
    font-size: 18px;
  }
}

.button-basic-desktop:hover {
  //filter: brightness(110%);
}

.button-basic-desktop:disabled:hover {
  filter: none !important;
}

.button-label-not-visible {
  opacity: 0;
}

.button-loader-container {
  position: absolute;
  z-index: 100;
}

.button-loader-container-mobile {
  font-size: 15px;
  content: "";
}

.button-loader-loading {
  color : #1f3deb !important;
  transition: 0s !important;
}

.button-basic-desktop strong,
.button-basic-desktop a{
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
  line-height: 22px;
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
  color: white;
  font-size: 14px;

  @include for-xs {
    font-size: 15px;
  }

  @include for-lg {
    font-size: 16px;
  }

  @include for-xl {
    font-size: 17px;
  }

  @include for-xxl {
    font-size: 18px;
  }
}

.button-basic-desktop:disabled {
  background: #EDEDED;
}

.button-basic-desktop:disabled strong {
  color: var(--color-dark-grey);
  font-weight: 500;
}

.chat-button-full {
  width: 100%;
}

.button-basic {
  position: relative;
  width: 100%;
  background: var(--color-bleach);
  color: var(--main-color);
  text-align: center;
  border: 0;
  height: 56px;
  font-size: 15px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s cubic-bezier(0.55, 0.06, 0.68, 0.19);
}

.button-basic strong {
  font-weight: normal;
  width: 100%;
  z-index: 1;
  -webkit-transition: all 0.7s ease;
  transition: all 0.7s ease;
  color: var(--main-color);
  font-size: 15px;
}

.button-basic:disabled strong {
  color: var(--color-dark-grey);
  font-weight: 500;
}

.button-basic-active strong {
  color: #fff !important;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.button-basic-active i {
  color: #fff;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.button-basic-active:disabled strong {
  color: #fff;
}


.button-basic-active span {
  left: 0px;
  position: absolute;
  background: #1f3deb;
  width: 0;
  z-index: 0;
  height: 100%;
  -webkit-animation: button-basic-active-animate 0.4s linear forwards;
  animation: button-basic-active-animate 0.4s linear forwards;
}

.button-basic-desktop-secondary {
  background: white;
  border: #1f3deb 1px solid;
}

.button-basic-desktop-secondary strong {
  color: #1f3deb;
}

.button-basic-desktop-secondary:hover {
  background: white;
}

.button-basic-desktop-modal {
  width: 100%;
  border-radius: 0px;
}

@-webkit-keyframes button-basic-active-animate {
  0% {
    width: 0%;
    height: 60px;
  }

  100% {
    width: 100%;
    height: 60px;
    left: 0;
    right: 0;
    color: #fff;
  }
}

@keyframes button-basic-active-animate {
  0% {
    width: 0%;
    height: 100%;
  }

  100% {
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    color: #fff;
  }
}

.button-basic-desktop-loading {
  background: #1f3deb !important;
}