@import '../../../css/Mixins.scss';


.uthere-loader-main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.uthere-loader-animate {
  position: relative;
  width: 20px;
  height: 20px;
}

.uthere-loader-animate svg {
  width: 20px;
  height: 20px;
}

.uthere-loader-animate svg {
  fill: none;
  stroke-width: 1px;
  stroke: #F4F4F4;
  stroke-dasharray: 90 200;
  stroke-linecap: round;
  animation: u-animate 2s linear infinite;
  position: absolute;
  top: 0px;
  left: 0px;

  &:nth-of-type(1) {
    fill: none;
    stroke-dasharray: 0;
    stroke: white;
    animation: none;
  }

  &:nth-of-type(2) {
    display: none;
  }
}

@keyframes u-animate {
  0% {
    stroke-dashoffset: 300;
  }

  100% {
    stroke-dashoffset: 0;
  }
}



.uthere-loader-desktop {
  width: 26px;
  height: 100%;
  height: 26px;
  display: flex;

  margin: auto;
  top: 0;
  bottom: 0;


  @include for-md {
    width: 32px;
    height: 32px;
  }

}

.uthere-loader-absolute {
  position: absolute;
}

@-webkit-keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.spiner-rotate {
  width: 100px;
  animation: spinner 2s infinite linear;
}