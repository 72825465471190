@mixin for-xs {
    @media (min-width: 340px) {
        @content;
    }
}

@mixin for-sm {
    @media (min-width: 576px) {
        @content;
    }
}

@mixin for-md {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin for-lg {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin for-xl {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin for-xxl {
    @media (min-width: 1400px) {
        @content;
    }
}
