@import './../../css/Mixins.scss';

.home-container {
  @apply text-neutral-900 flex flex-col items-center flex-shrink-0 pt-12 md:pt-16 xl:pt-18 2xl:pt-24;
}


.home-container .color-blue {
  color: var(--uthere-blue)
}


.home-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: 24px;
  max-width: 743px;

  @include for-xs {
    gap: 32px;
  }

  @include for-md {
    max-width: 550px;
  }

  @include for-xl {
    max-width: 900px;
  }
}

.home-title-container-start {
  @include for-md {
    align-self: flex-start;
  }
}

.home-container .bold {
  font-weight: 550;
}

.home-container h1 {
  text-align: center;
  font-size: 25px;
  margin-bottom: 0px;
  font-weight: 400;
  line-height: 140%;

  @include for-xs {
    font-size: 27px;
  }

  @include for-md {
    font-size: 38px;
  }

  @include for-xl {
    font-size: 47px;
  }
}

.home-container h2 {
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 0px;

  @include for-xs {
    font-size: 24px;
  }

  @include for-md {
    font-size: 32px;
  }

  @include for-xl {
    font-size: 38px;
  }
}

.home-container h3 {
  text-align: center;
  margin-bottom: 0px !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;
  @include for-xs {
    font-size: 21px;
  }
  @include for-md {
    font-size: 27px;
  }

  @include for-xl {
    font-size: 29px;
  }
}

.home-container h4 {
  font-size: 19px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 0px;
  text-align: center;

  @include for-sm {
    font-size: 23px;
  }

  @include for-lg {
    font-size: 24px;
  }
}


.home-container h5 {
  font-size: 17px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 0px;

  @include for-md {
    font-size: 20px;
  }
}

.home-container h6 {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 0px;


  @include for-xs {
    font-size: 15px;
  }

  @include for-md {
    font-size: 16px;
  }

  @include for-xl {
    font-size: 18px;
  }
}

.home-container small {
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 170%;
  margin-bottom: 0px;
}



.home-container-content {
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-self: stretch;
}
