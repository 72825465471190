@import '../../../css/Mixins.scss';


.u-notification {
    background: #FFFFFF;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 27px 11px rgba(40, 40, 56, 0.01), 0px 15px 9px rgba(40, 40, 56, 0.02), 0px 7px 7px rgba(40, 40, 56, 0.03), 0px 2px 4px rgba(40, 40, 56, 0.04), 0px 0px 0px rgba(40, 40, 56, 0.04);
    box-sizing: border-box;
    border-radius: 16px 16px 16px 16px;
    position: relative;
    padding: 24px;
    margin-bottom: 24px
}

.u-notification-label {
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #319607;
}

.u-notification-label-claim {
    color: #AF1E14;
}

.u-notification-content {
    display: flex;
    margin-top: 20px;
    padding-bottom: 24px;
}

.u-notification-content-detail {
    display: flex;
    justify-content: space-between;
}

.u-notification-content-detail-col1 {
    font-size: 12px;
    line-height: 150%;
}

.u-notification-content-detail-col2 {
    line-height: 150%;
}

.u-notification-content-detail-col3 {
    line-height: 150%;
    font-weight: 600;
}


.u-notification-close {
    top: -12px;
    right: -12px;
    position: absolute;
}

.u-notification-close button {
    background: #999999;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.u-notification-lara-name {
    position: relative;
    width: 48px;
    height: 48px;
    text-align: center;
}

.u-notification-lara {
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #999999;
}

.u-notification-lara-name-img {
    position: relative;
    border-radius: 50%;
    width: 48px;
    height: 48px;
}

.u-notification-online {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #319607;
    content: "";
    position: absolute;
    margin-top: 2px;
}


.u-notification-text {
    display: flex;
    align-items: center;
    line-height: 150%;
    font-size: 15px;
    font-weight: 400;
    padding-left: 16px;
}

.u-notification-dark {
    background: #333333;
    border: 0px solid #ededed;
}

.u-notification-button {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
}

.u-notification-buttons {
    display: flex;
    justify-content: center;
}

.u-notification-buttons-col-2>button {
    &:last-child {
        margin-left: 16px;
    }
}

.u-notification-desktop {
    border: 1px solid #F2F2F2;
    box-shadow: 0px 7px 7px rgba(40, 40, 56, 0.03), 0px 2px 4px rgba(40, 40, 56, 0.04);
    background: white;
    border-radius: 16px;
    padding: 24px 24px 24px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include for-xs {
        width: 100%;
        padding: 24px 24px 24px;
    }

    @include for-sm {
        width: 100%;
        padding: 24px 24px 24px;
    }

    @include for-md {
        width: 316px;
        padding: 24px 24px 28px;
    }

    @include for-lg {
        width: 384px;
        padding: 24px 24px 28px;
    }

    @include for-xl {
        width: 520px;
        padding: 24px 24px 31px;
    }

    @include for-xxl {
        width: 560px;
        padding: 24px 24px 28px;
    }
}

.u-notification-desktop-header {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    line-height: 150%;
    text-transform: uppercase;
    color: #999999;

    @include for-xs {
        font-size: 12px;
    }

    @include for-sm {
        font-size: 12px;
    }

    @include for-md {
        font-size: 12px;
    }

    @include for-lg {
        font-size: 12px;
    }

    @include for-xl {
        font-size: 14px;
    }

    @include for-xxl {
        font-size: 14px;
    }
}

.u-notification-desktop-body {
    display: flex;
    align-items: center;
    justify-content:baseline;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: #333333;

    @include for-xs {
        font-size: 15px;
        justify-content:baseline;
        
    }

    @include for-sm {
        font-size: 15px;
        justify-content:baseline;
    }

    @include for-md {
        font-size: 15px;
        justify-content:baseline;
    }

    @include for-lg {
        font-size: 16px;
        justify-content:space-between;
    }

    @include for-xl {
        font-size: 17px;
        justify-content:space-between;
    }

    @include for-xxl {
        font-size: 18px;
        justify-content:space-between;
    }
}

.u-notification-desktop-body img {
    width: 48px;
    height: 48px;
    margin-right: 16px;

    @include for-xs {
        margin-right: 16px;
        width: 48px;
        height: 48px;
    }

    @include for-sm {
        margin-right: 16px;
        width: 48px;
        height: 48px;
    }

    @include for-md {
        margin-right: 16px;
        width: 48px;
        height: 48x;
    }

    @include for-lg {
        margin-right: 16px;
        width: 48px;
        height: 48x;
    }

    @include for-xl {
        margin-right: 16px;
        width: 52px;
        height: 52px;
    }

    @include for-xxl {
        margin-right: 20px;
        width: 56px;
        height: 56px;
    }
}

.u-notification-desktop-body-button {
    margin-left: 0px;
    padding-top: 8px;

    @include for-xs {
        padding-top: 8px;
        margin-left: 0px;
    }

    @include for-sm {
        padding-top: 8px;
        margin-left: 0px;
    }

    @include for-md {
        margin-left: 0px;
        padding-top: 8px;
    }

    @include for-lg {
        margin-left: 0px;
        padding-top: 8px;
    }

    @include for-xl {
        padding-top: 0px;
        margin-left: 18px;
    }

    @include for-xxl {
        padding-top: 0px;
        margin-left: 32px;
    }
}