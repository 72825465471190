@import '../../../../../css/Mixins.scss';

.notification-modal-list {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
}

.notification-modal-list-header {
    height: 32px;
    width: 560px;
    display: flex;  
    align-items: end;
    padding-right: 32px;
    justify-content: end;

    @include for-xs {
        width: 100%;
        height: 112px;
        padding-right: 32px;
    }

    @include for-sm {
        width: 100%;
        height: 112px;
        padding-right: 32px;
    }

    @include for-md {
        width: 316px;
        height: 100px;
        padding-right: 0px;
    }

    @include for-lg {
        padding-right: 0px;
        height: 100px;
        width: 384px;
    }

    @include for-xl {
        padding-right: 0px;
        height: 164px;
        width: 520px;
    }

    @include for-xxl {
        padding-right: 0px;
        height: 188px;
        width: 560px;
    }
}

.notification-modal-list-header img {
    width: 32px;
    height: 32px;
}

.notification-modal-list-container::-webkit-scrollbar {
    display: none;
  }
  

.notification-modal-list-container {
    display: flex;
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-direction: column;
    padding: 20px 0px 16px;
    width: 100%;
    align-items: end;
    gap: 32px;
    align-items: center;

    @include for-xs {
        padding: 20px 30px 32px;
    }

    @include for-sm {
        padding: 20px 30px 32px;
    }

    @include for-md {
        width: 316px;
    }

    @include for-lg {
        width: 384px;
    }

    @include for-xl {
        width: 520px;
    }

    @include for-xxl {
        padding: 20px 0px 72px 0px;
        width: 560px;
    }
}

.notification-modal-list-header-fader {
    height: 30px;
    width: 100%;
    margin-bottom: -10px;
    background: wheat;
    z-index: 999999999999;
    background: linear-gradient(180deg, #434343 30.08%, rgba(67, 67, 67, 0.567708) 56.64%, rgba(67, 67, 67, 0) 100%)
}

.notification-modal-list-close {
    cursor: pointer;
}