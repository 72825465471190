@import './css/Variables.scss';
@import './css/Mixins.scss';

:root {

   /* --------------------------------------------------
    /*       Widths
    /* --------------------------------------------------*/
    --uthere-xxl-width : 992px;

    /* --------------------------------------------------
    /*       Colors
    /* --------------------------------------------------*/
    --uthere-dark : #333;
    --uthere-blue : #1f3deb;
    --uthere-white : #fff;


    --main-color: #1f3deb;
    --secondary-color: #ff2a06;
      --tertiary-color: #ffc648;
    --navbar-color: #ffffff;
    --navbar-bottom-border-color: #bdbdbd;
    --menu-button: #757575;
    --mobileInputViewBackground: #eef4fc;
    --mobileInputViewBorderTopColor: #eef4fc;
    --disabled-color: #959595;
    --pop-up-background-color: #ffffff;
    --message-from-color: rgb(231, 231, 231);
    --message-to-color: #5e5e5e;
    --color-dark-charcoal: #333333;
    --color-million-grey: #999999;
    --color-bleach: #e8e7e7;
    --color-the-beauty-doctor: #b9bcc4;
    --color-grey-white: #F8F8F9;
    --color-border: #E0E0E0;

    --color-dark: #333333;
    --color-dark-grey: #999999;
    --color-medium-grey: #CDCECE;
    --color-light-grey: #EDEDED;
    --color-grey-submenu: #F6F6F6;
    --color-white: #FFFFFF;
    --color-blue: #1F3DEB;
    --color-medium-blue: #6E7FDB;
    --color-light-blue: #EBEFFF;
    --color-brown-dp: #A6884D;
    --color-red: #E72E0E;
    --swiper-pagination-bullet-horizontal-gap: 6px;
    --swiper-pagination-color: #999;
}




@font-face {
  font-family: Montserrat;
  src: local("Montserrat"), url("./assets/fonts/Montserrat.ttf");
  font-display: swap;
}

@font-face {
  font-family: Lato-Bold;
  src: local("Lato-Bold"), url("./assets/fonts/Lato-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: local("Lato"), url("./assets/fonts/Lato-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: local("Montserrat Bold"), url("./assets/fonts/Montserrat-Bold.ttf");
  font-display: swap;
}


@each $paddingMargin,
$i in $paddingsMargins {
    .u-p-#{$paddingMargin} {
        padding: #{$i}px !important;
    }

    .u-pt-#{$paddingMargin} {
        padding-top: #{$i}px !important;
    }

    .u-pe-#{$paddingMargin} {
        padding-right: #{$i}px !important;
    }

    .u-pb-#{$paddingMargin} {
        padding-bottom: #{$i}px !important;
    }

    .u-ps-#{$paddingMargin} {
        padding-left: #{$i}px !important;
    }
}

@media (min-width: 576px) {

    @each $paddingMargin,
    $i in $paddingsMargins {
        .u-p-sm-#{$paddingMargin} {
            padding: #{$i}px !important;
        }

        .u-pt-sm-#{$paddingMargin} {
            padding-top: #{$i}px !important;
        }

        .u-pe-sm-#{$paddingMargin} {
            padding-right: #{$i}px !important;
        }

        .u-pb-sm-#{$paddingMargin} {
            padding-bottom: #{$i}px !important;
        }

        .u-ps-sm-#{$paddingMargin} {
            padding-left: #{$i}px !important;

        }
    }
}

@media (min-width: 768px) {

    @each $paddingMargin,
    $i in $paddingsMargins {
        .u-p-md-#{$paddingMargin} {
            padding: #{$i}px !important;
        }

        .u-pt-md-#{$paddingMargin} {
            padding-top: #{$i}px !important;
        }

        .u-pe-md-#{$paddingMargin} {
            padding-right: #{$i}px !important;
        }

        .u-pb-md-#{$paddingMargin} {
            padding-bottom: #{$i}px !important;
        }

        .u-ps-md-#{$paddingMargin} {
            padding-left: #{$i}px !important;

        }
    }
}

@media (min-width: 992px) {

    @each $paddingMargin,
    $i in $paddingsMargins {
        .u-p-lg-#{$paddingMargin} {
            padding: #{$i}px !important;
        }

        .u-pt-lg-#{$paddingMargin} {
            padding-top: #{$i}px !important;
        }

        .u-pe-lg-#{$paddingMargin} {
            padding-right: #{$i}px !important;
        }

        .u-pb-lg-#{$paddingMargin} {
            padding-bottom: #{$i}px !important;
        }

        .u-ps-lg-#{$paddingMargin} {
            padding-left: #{$i}px !important;

        }
    }
}

@media (min-width: 1200px) {

    @each $paddingMargin,
    $i in $paddingsMargins {
        .u-p-xl-#{$paddingMargin} {
            padding: #{$i}px !important;
        }

        .u-pt-xl-#{$paddingMargin} {
            padding-top: #{$i}px !important;
        }

        .u-pe-xl-#{$paddingMargin} {
            padding-right: #{$i}px !important;
        }

        .u-pb-xl-#{$paddingMargin} {
            padding-bottom: #{$i}px !important;
        }

        .u-ps-xl-#{$paddingMargin} {
            padding-left: #{$i}px !important;

        }
    }
}


@each $paddingMargin,
$i in $paddingsMargins {
    .u-m-#{$paddingMargin} {
        margin: #{$i}px !important;
    }

    .u-mt-#{$paddingMargin} {
        margin-top: #{$i}px !important;
    }

    .u-me-#{$paddingMargin} {
        margin-right: #{$i}px !important;
    }

    .u-mb-#{$paddingMargin} {
        margin-bottom: #{$i}px !important;
    }

    .u-ms-#{$paddingMargin} {
        margin-left: #{$i}px !important;
    }
}

@media (min-width: 576px) {

    @each $paddingMargin,
    $i in $paddingsMargins {
        .u-m-sm-#{$paddingMargin} {
            margin: #{$i}px !important;
        }

        .u-mt-sm-#{$paddingMargin} {
            margin-top: #{$i}px !important;
        }

        .u-me-sm-#{$paddingMargin} {
            margin-right: #{$i}px !important;
        }

        .u-mb-sm-#{$paddingMargin} {
            margin-bottom: #{$i}px !important;
        }

        .u-ms-sm-#{$paddingMargin} {
            margin-left: #{$i}px !important;
        }
    }
}

@media (min-width: 768px) {

    @each $paddingMargin,
    $i in $paddingsMargins {
        .u-m-md-#{$paddingMargin} {
            margin: #{$i}px !important;
        }

        .u-mt-md-#{$paddingMargin} {
            margin-top: #{$i}px !important;
        }

        .u-me-md-#{$paddingMargin} {
            margin-right: #{$i}px !important;
        }

        .u-mb-md-#{$paddingMargin} {
            margin-bottom: #{$i}px !important;
        }

        .u-ms-md-#{$paddingMargin} {
            margin-left: #{$i}px !important;
        }
    }
}

@media (min-width: 992px) {

    @each $paddingMargin,
    $i in $paddingsMargins {
        .u-m-lg-#{$paddingMargin} {
            margin: #{$i}px !important;
        }

        .u-mt-lg-#{$paddingMargin} {
            margin-top: #{$i}px !important;
        }

        .u-me-lg-#{$paddingMargin} {
            margin-right: #{$i}px !important;
        }

        .u-mb-lg-#{$paddingMargin} {
            margin-bottom: #{$i}px !important;
        }

        .u-ms-lg-#{$paddingMargin} {
            margin-left: #{$i}px !important;
        }
    }
}

@media (min-width: 1200px) {

    @each $paddingMargin,
    $i in $paddingsMargins {
        .u-m-xl-#{$paddingMargin} {
            margin: #{$i}px !important;
        }

        .u-mt-xl-#{$paddingMargin} {
            margin-top: #{$i}px !important;
        }

        .u-me-xl-#{$paddingMargin} {
            margin-right: #{$i}px !important;
        }

        .u-mb-xl-#{$paddingMargin} {
            margin-bottom: #{$i}px !important;
        }

        .u-ms-xl-#{$paddingMargin} {
            margin-left: #{$i}px !important;
        }
    }
}

@each $fontSize, $i in $fontSizes {
    .u-fs-#{$fontSize} {
      font-size: #{($i/$fontSizeDefaultMin)+'rem'} !important;
      transition: .3s all linear;
      letter-spacing: 0;
      @include  for-xs {
        font-size: #{($i/$fontSizeDefault)+'rem'} !important;
      }
    }
  }

  @media (min-width: 576px) {
    @each $fontSize, $i in $fontSizes {
      .u-fs-sm-#{$fontSize} {
        font-size: #{$i/$fontSizeDefault+'rem'} !important;
        transition: .3s all linear;
        letter-spacing: 0;
     }
    }
  }
  @media (min-width: 768px) {
    @each $fontSize, $i in $fontSizes {
      .u-fs-md-#{$fontSize} {
        font-size: #{$i/$fontSizeDefault+'rem'} !important;
        transition: .3s all linear;
        letter-spacing: 0;
     }
    }
  }
  @media (min-width: 992px) {
    @each $fontSize, $i in $fontSizes {
      .u-fs-lg-#{$fontSize} {
        font-size: #{$i/$fontSizeDefault+'rem'} !important;
        transition: .3s all linear;
        letter-spacing: 0;
     }
    }
  }
  @media (min-width: 1200px) {
    @each $fontSize, $i in $fontSizes {
      .u-fs-xl-#{$fontSize} {
        font-size: #{$i/$fontSizeDefault+'rem'} !important;
        transition: .3s all linear;
        letter-spacing: 0;
     }
    }
  }
  //***
  // font-weight
  //***
  @each $fontWeight, $i in $fontWeights {
    .u-fw-#{$fontWeight} {
      font-family: $i !important;
    }
  }
  @media (min-width: 992px) {
    @each $fontWeight, $i in $fontWeights {
      .u-fw-lg-#{$fontWeight} {
        font-family: $i !important;
      }
    }
  }
  @media (min-width: 1200px) {
    @each $fontWeight, $i in $fontWeights {
      .u-fw-xl-#{$fontWeight} {
        font-family: $i !important;
      }
    }
  }
  //***
  // text-align
  //***
  @each $fontAlign, $i in $fontAligns {
    .u-text-#{$fontAlign} {
      text-align: $i !important;
      transition: .3s all linear;
    }
  }
  @media (min-width: 576px) {
    @each $fontAlign, $i in $fontAligns {
      .u-text-sm-#{$fontAlign} {
        text-align: $i !important;
        transition: .3s all linear;
      }
    }
  }
  @media (min-width: 768px) {
    @each $fontAlign, $i in $fontAligns {
      .u-text-md-#{$fontAlign} {
        text-align: $i !important;
        transition: .3s all linear;
      }
    }
  }
  @media (min-width: 992px) {
    @each $fontAlign, $i in $fontAligns {
      .u-text-lg-#{$fontAlign} {
        text-align: $i !important;
        transition: .3s all linear;
      }
    }
  }
  @media (min-width: 1200px) {
    @each $fontAlign, $i in $fontAligns {
      .u-text-xl-#{$fontAlign} {
        text-align: $i !important;
        transition: .3s all linear;
      }
    }
  }
  //***
  // Colors
  //***
  @each $color, $i in $colors {
    .u-color-#{$color} {
      color: $i !important;

    }
  }


  //***
  // line-height
  //***
  @each $lineHeight, $i in $lineHeights {
    .u-lh-#{$lineHeight} {
      line-height: #{$i+'%'} !important;
    }
  }
  @media (min-width: 992px) {
    @each $lineHeight, $i in $lineHeights {
      .u-lh-lg-#{$lineHeight} {
        line-height: #{$i+'%'} !important;
      }
    }

  }
  @media (min-width: 1200px) {
    @each $lineHeight, $i in $lineHeights {
      .u-lh-xl-#{$lineHeight} {
        line-height: #{$i+'%'} !important;
      }
    }
  }

  textarea:focus, input:focus{
    outline: none;
}
