.u-btn-small {
    background: #1f3deb;
    border-radius: 16px;
    font-size: 15px;
    color: white;
    line-height: 150%;
    width: 100%;
    text-decoration: none !important;
    padding: 16px 16px;
}

.link-button {
    border: none;
    background: none;
    outline: none;
    color: #1F3DEB;
}

.link-button:disabled  {
    color: #959595 !important
}