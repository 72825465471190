@import './../../../css/Mixins.scss';

.uthere-home-header {
    position: fixed;
    top: 0;
    background: var(--uthere-white);
    width: 100%;
    z-index: 10000;

}

.header-custom-action {
    color: var(--main-color);
}

.uthere-home-header-up {
    height: 50px;
    padding: 0px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

    @include for-xs {
        height: 56px;
    }


    @include for-md {
        height: 64px;
        padding: 0px 24px;
    }

    @include for-xl {
        height: 74px;
        padding: 0px 32px;
    }

    @include for-xxl {
        height: 96px;
    }
}

.uthere-home-header-up-boder {
    border-bottom: 2px solid #EDEDED;
}

.uthere-home-header-scroll {
    background: #FFFFFF;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1);
    transition: all 0.3s linear;
}


.uthere-home-logo {
    width: 80px;
    height: 17.4px;
    flex-shrink: 0;

    @include for-md {
        width: 88px;
        height: 19.2px;
    }

    @include for-xl {
        width: 96px;
        height: 20.945px;
    }
}

.uthere-home-header-claim {
    display: none;
    font-size: 11px;
    white-space: nowrap;

    @include for-md {
        display: block;
    }

    @include for-xl {
        font-size: 12px;
    }
}

.uthere-home-header-container-right {
    display: flex;
    align-items: flex-end;
    gap: 16px;

    @include for-xl {
        gap: 24px;
    }
}

.uthere-home-header-container-left {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-end;
    width: 15%;

    @include for-md {
        width: 33%;
        gap: 24px;
    }
}

.uthere-home-header-button {
    display: flex;
    padding: 10px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: var(--primary-primary-500-default, #1F3DEB);
    border: none;
    color: white;
    font-size: 13px;
    font-weight: 500;
    opacity: 0;

    @include for-xs {
        font-size: 14px;
    }

    @include for-xl {
        font-size: 15px;
    }


    @include for-xxl {
        font-size: 16px;
    }
}

.uthere-home-header-button-show {
    animation: uthere-home-header-button-show-animation 0.3s forwards !important;
}

@keyframes uthere-home-header-button-show-animation {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }

    100% {
        opacity: 1;
        display: block;
        transform: translateY(0);
    }
}

.uthere-home-header-button-hidden {
    animation: uthere-home-header-button-show-hidden-animation 0.3s forwards;
}

@keyframes uthere-home-header-button-show-hidden-animation {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-10px);
    }
}

.uthere-home-header-hamburger {
    gap: 4px;
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;

    @include for-md {
        gap: 6px;
    }
}

.uthere-home-header-hamburger.is-active {
    background: transparent;
}

.uthere-home-header-hamburger.is-active .line:nth-child(1) {
    transform: translateY(4px) rotate(45deg);

    @include for-md {
        transform: translateY(6px) rotate(45deg);
    }
}

.uthere-home-header-hamburger.is-active .line:nth-child(2) {
    opacity: 0;
    background: white;
    border-color: white;
}


.uthere-home-header-hamburger.is-active .line:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);

    @include for-md {
        transform: translateY(-8px) rotate(-45deg);
    }

    border-color: #333333;
}



.uthere-home-header-hamburger:hover {
    cursor: pointer;
}


.uthere-home-header-hamburger .line {
    width: 13.3px;
    background: #333333;
    display: block;
    height: 1px;
    transition: all 0.3s 0s ease-in-out;

    @include for-xs {
        width: 16px;
    }

    @include for-md {
        width: 19px;
    }
}

.uthere-home-header-hamburger-menu {
    width: 100%;
    height: 100%;
    background-color: white;
    position: fixed;
    transition: .3s all linear;
    max-height: 0%;
    opacity: 0;
    z-index: 99999;
    pointer-events: none;
    padding: 16px 40px 108px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    top: 50px;

    @include for-xs {
        top: 56px
    }
}

.uthere-home-header-hamburger-menu-top {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    color: var(--neutral-neutral-700, #666);
    align-items: start;
    font-size: 14px;
    gap: 32px;
    width: 100%;
    padding-bottom: 32px;


    @include for-md {
        font-size: 14px;
    }

    @include for-xl {
        font-size: 15px;
    }

    @include for-xxl {
        font-size: 16px;
    }
}

.uthere-home-header-menu-seprator {
    width: 100%;
    height: 1px;
    margin: 12px 0px;
    border-bottom: solid 1px #E6E6E6;
}

.uthere-home-header-hamburger-menu-top-options {
    display: flex;
    font-size: 14px;
    flex-direction: column;
    font-weight: 400;
    align-items: start;
    gap: 16px;
    width: 100%;

    @include for-md {
        font-size: 14px;
    }

    @include for-xl {
        font-size: 15px;
    }

    @include for-xxl {
        font-size: 16px;
    }
}


.uthere-home-header-hamburger-menu.is-active {
    max-height: 100%;
    opacity: 1;
    pointer-events: all;
}

.uthere-home-header-hamburger-menu a {
    text-decoration: none !important;
    color: #333 !important;
}

.uthere-home-header-desktop-container {
    position: relative;
}

.uthere-home-header-desktop-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    background: #F5F5FA;
    box-shadow: 0px 1px 2px rgba(33, 35, 38, 0.08);
    border-radius: 24px;
    z-index: 99999999;
    right: 0px;
    top: 10px;
    gap: 24px;
    min-width: 250px;

    @include for-xl {
        top: 20px;
        width: 300px;
        gap: 32px;
        padding: 32px;
    }

    @include for-xxl {
        width: 360px;
        gap: 32px;
        padding: 40px;
    }
}

.uthere-menu-opacity {
    width: 100vw;
    cursor: pointer;
    height: 100vh;
    position: fixed;
    background-color: transparent;
    z-index: 999;
    opacity: 0;
    top: 0;
    left: 0;
    animation: uthere-menu-opacity 0.1s ease-in forwards;
}

@keyframes uthere-menu-opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.3;
    }
}

.uthere-menu-opacity-hidden {
    animation: uthere-menu-opacity-hidden 0.3s ease-out forwards;
}

@keyframes uthere-menu-opacity-hidden {
    0% {
        opacity: 0.3;
    }

    100% {
        opacity: 0;
    }
}

.uthere-home-header-title {
    text-transform: uppercase;
    color: var(--neutral-neutral-900, #333);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    text-transform: uppercase;

    @include for-xl {
        font-size: 17px;
    }

    @include for-xxl {
        font-size: 18px;
    }
}

.uthere-home-header-back svg {
    cursor: pointer;
    width: 20px;
    height: 20px;

    @include for-xs {
        width: 24px;
        height: 24px;
    }

    @include for-xl {
        width: 28px;
        height: 28px;
    }

    @include for-xl {
        width: 32px;
        height: 32px;
    }
}

.uthere-home-header-progress-container {
    z-index: 9;
    position: absolute;
    width: 100%;
    top: 47px;


    @include for-xs {
        top: 53px;
    }

    @include for-md {
        top: 62px;
    }

    @include for-xl {
        top: 69px;
    }

    @include for-xxl {
        top: 93px;
    }
}

.uthere-home-header-process-progress {
    background: #CCCCCC;
    width: 0;
    height: 4px;
    transition: 0.6s;
}

.uthere-home-header-realtive {
    position: relative;
}

.desktop-header-box-price {
    border-radius: 8px;
    background: #F5F5FA;
    box-shadow: 0px 3px 2px -1px rgba(0, 0, 0, 0.02), 0px 1px 1px -1px rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    padding: 8px;

    @include for-md {
        padding: 8px 12px;
    }

    @include for-xl {
        padding: 14px;
    }

    @include for-xxl {
        padding: 16px;
    }
}

.desktop-header-box-price-main {
    font-family: 'Lato-Bold';
    color: #666666;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    line-height: 0;
    display: flex;
    font-size: 26px;

    @include for-md {
        font-size: 32px;
    }

    @include for-xl {
        font-size: 38px;
    }

    @include for-xl {
        font-size: 40px;
    }
}

.desktop-header-box-price-secondary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.desktop-header-box-price-secondary-cents {
    font-size: 14px;
    font-family: 'Lato-Bold';
    color: #666666;

    @include for-md {
        font-size: 16px;
    }

    @include for-xl {
        font-size: 20px;
    }

    @include for-xl {
        font-size: 22px;
    }

}

.desktop-header-box-price-secondary-period {
    font-weight: 400;
    font-size: 8px;
    padding-left: 7px;
    font-family: 'Lato';
    color: #B3B3B3;

    @include for-xl {
        font-size: 10px;
    }
}

.desktop-header-box-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    border-radius: 12px;
    background: #1F3DEB;
    color: white;
    padding: 10px 20px;
    font-size: 14px;

    @include for-xl {
        padding: 12px 22px;
        font-size: 16px;
    }

    @include for-xxl {
        padding: 14px 24px;
        font-size: 16px;
    }
}

.desktop-header-box-price-other {
    color: #CCC;
    font-family: "Lato";
    font-size: 24px;
    margin-left: 16px;
    position: relative;
    display: flex;
}

.desktop-header-box-price-other div {
    background: #B3B3B3;
    width: 100%;
    bottom: 48%;
    height: 3px;
    position: absolute;
}

.header-desktop-proposal-container {
    display: flex;
    gap: 4px;
    opacity: 0;
    transition: opacity 0.5s;


    @include for-xxl {
        gap: 8px;
    }
}


.header-desktop-proposal-container {
    display: flex;
    gap: 4px;
    opacity: 0;
    transition: opacity 0.5s;

    @include for-xxl {
        gap: 8px;
    }
}

.header-desktop-proposal-container-detail {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    cursor: pointer;
}

.header-desktop-proposal-container-detail img {
    width: 60px;
    height: 22px;

    @include for-sm {
        width: 60px;
        height: 22px;
    }

    @include for-md {
        width: 60px;
        height: 22px;
    }

    @include for-lg {
        width: 60px;
        height: 22px;
    }

    @include for-xl {
        width: 68px;
        height: 25px;

    }

    @include for-xxl {
        width: 88px;
        height: 33px;
    }
}

.header-desktop-proposal-container-price {
    background: #F2F2F2;
    border-radius: 8px;
    padding: 0px 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 150%;
    width: 72px;
    height: 28px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--insurerColor) !important;


    @include for-sm {
        width: 49px;
        height: 19px;
        font-size: 8px;
    }

    @include for-md {
        width: 49px;
        height: 19px;
        font-size: 8px;
    }


    @include for-lg {
        width: 49px;
        height: 19px;
        font-size: 8px;
    }

    @include for-xl {
        width: 56px;
        height: 21px;
        font-size: 9px;
    }

    @include for-xxl {
        width: 72px;
        height: 28px;
        font-size: 11px;
    }

}

.header-desktop-proposal-container-price:hover {
    color: white !important;
    background: var(--insurerColor) !important;
}

.header-desktop-proposal-container-price-selected {
    color: white !important;
    font-weight: 700;
    background: var(--insurerColor) !important;
}

.header-desktop-proposal-price-main {

    @include for-sm {
        font-size: 10px;
    }

    @include for-md {
        font-size: 10px;
    }

    @include for-lg {
        font-size: 10px;
    }

    @include for-xl {
        font-size: 11px;
    }

    @include for-xxl {
        font-size: 14px;
    }
}

.header-desktop-proposal-container-ball {
    width: 10px;
    height: 10px;
    background: #96BF43;
    border-radius: 50%;
    position: absolute;
    bottom: -5px;
    opacity: 0;
    transition: 0.6s;
    z-index: 999;
}

.uthere-home-header-hamburger-menu-bottom-client {
    color: var(--neutral-neutral-700, #666);
    font-size: 18px;
    font-weight: 700;
    line-height: 124%;

    @include for-md {
        font-size: 19px;
    }

    @include for-xl {
        font-size: 20px;
    }

    @include for-xxl {
        font-size: 21px;
    }


}

.uthere-home-header-hamburger-menu-bottom {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.uthere-home-header-hamburger-menu-bottom-button {
    display: flex;
    padding: 20px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    border: 1px solid var(--primary-primary-500-default, #1F3DEB);
    color: var(--primary-primary-500-default, var(--primary-primary-500-default, #1F3DEB));
    background: transparent;
    text-align: center;
    font-size: 15px;
    font-weight: 450;
    line-height: normal;

}

.uthere-home-header-hamburger-menu-bottom-user-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: var(--neutral-neutral-900, #333);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    @include for-md {
        font-size: 17px;
    }

    @include for-xl {
        font-size: 18px;
    }
}

.uthere-home-header-hamburger-menu-bottom-user-options-items {
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 12px;
    font-size: 14px;

    @include for-md {
        font-size: 14px;
    }

    @include for-xl {
        font-size: 15px;
    }


    @include for-xxl {
        font-size: 16px;
    }
}

.uthere-home-header-hamburger-menu-bottom-user-options-items svg {
    width: 24px;
    height: 24px;
}

.uthere-home-header-desktop-menu a {
    text-decoration: none !important;
    color: var(--neutral-neutral-900, #333) !important;
    cursor: pointer;
}

.uthere-home-header-desktop-menu-top {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: var(--neutral-neutral-900, #333);
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    padding-bottom: 28px;

    @include for-md {
        font-size: 14px;
    }

    @include for-xl {
        font-size: 15px;
    }

    @include for-xxl {
        font-size: 16px;
    }
}

.uthere-home-header-desktop-menu-top a {
    cursor: pointer;
}

.uthere-home-header-desktop-menu-top div {
    cursor: pointer;
}

.link-soon {
    display: flex;
    position: relative;
}

.link-soon-soon {
    top: -5px;
    right: -70px;
    position: absolute;
    display: inline-flex;
    padding: var(--space-x-4-space-4, 4px) var(--space-x-4-space-8, 8px);
    color: var(--neutral-neutral-700, #666);
    font-family: Montserrat;
    font-size: 9.5px;
    font-style: normal;
    font-weight: 450;
    line-height: 150%;
    justify-content: center;
    align-items: center;
    gap: var(--space-x-4-space-8, 8px);

    border-radius: var(--space-x-4-space-8, 8px);

    background: linear-gradient(180deg, #F5F5FA 0%, #EFEFFF 100%);
    border-radius: var(--space-x-4-space-8, 8px);

    background: linear-gradient(180deg, #F5F5FA 0%, #EFEFFF 100%);
}
