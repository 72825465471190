@import '../../css/Mixins.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;



body,
html {
    position: relative;
    overflow: scroll;
}

html{
  font-size: 16px;
}

.home-title {
    text-align: center;
    padding: 0px 40px;
}

.home-separator {
  height: 1px;
  background:  #DADFFC;
  margin-top: 72px;
  @include for-xs {
    margin-top: 72px;
  }
  @include for-sm {
    margin-top: 72px;
  }
  @include for-md {
    margin-top: 72px;
  }
  @include for-lg {
    margin-top: 80px;
  }
  @include for-xl {
    margin-top: 64px;
  }
  @include for-xxl {
    margin-top: 64px;
  }
}

.u-bg-soft-grey {
    background-color: #F5F5FA !important;
}

.u-border-bottom-medium-grey {
    border-bottom: 1px solid #cdcece;
}

.home-page-container {
  margin-top: 64px;

  @include for-lg {
    margin-top: 96px;
  }
  @include for-xl {
    margin-top: 96px;
  }
  @include for-xxl {
    margin-top: 96px;
  }
}

.container-bg-cover {
    background: url("../../assets/home/cover/bg-cover.png");
    width: 100%;
    height: 140px;
    background-size: auto 100% !important;

    @include for-sm {
      height: 210px !important;
    }

    @include for-md {
      height: 210px !important;
    }

    @include for-lg {
      height: 216px !important;
    }

    @include for-xl {
      height: 216px !important;
    }
  }
